@import '../../variables.scss';

.navbar{
    display: flex;
    width: 100%;
    height: 55px;
    background-color: $container-color;
    border-bottom: solid 1px $borderColor;
    align-items: center;
    justify-content: space-between;

    .searchBox{
        padding: 8px 10px;
        display: flex;
        justify-content: space-between;
        width: 25vw;
        margin-right: 15px;
        background-color: $text-field-color;
        border-radius: 8px;
        font-size: 14px;
        color: $hintColor;
        align-items: center;
        cursor: pointer;

        .icon{
            font-size: 24px;
        }

        p{
            font-weight: 400;
        }
    }

    .user{
        margin-left: 15px;
        display: flex;
        align-items: center;

        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            cursor: pointer;
        }

        .info{
            display: flex;
            flex-direction: column;
            align-items: end;

            .name{
                font-size: 14px;
                color: #000;
            }

            .type{
                font-size: 12px;
                color: $hintColor;
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .navbar{
        background-color: $containerColorDark;
        border-bottom: solid 1px $containerColorDark;
        .searchBox{
            background-color: $text-field-colorDark;
        }
    
        .user{
            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
            }
    
            .info{
                .name{
                    font-size: 14px;
                    color: $darkTextColor;
                }
    
                .type{
                    font-size: 12px;
                    color: $hintColor;
                }
            }

            .box{
                background-color: $containerColorDark;
            }
        }
    }
}