@import '../../../variables.scss';

.installments {
    flex: 3;
    padding: 15px;
    background-color: $container-color;
    border-radius: 12px;
    height: 300px;

    .chart{
        width: 100%;
        height: 90%;
    }

    .title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .info{
            display:  flex;
            align-items: center;

            .secion{
                display: flex;
                gap: 5px;
                align-items: center;

                .circle{
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    margin-right: 15px;
                }

                p{
                    font-size: 1.1vw;
                }
            }
        }

        h3{
            font-size: 1.3vw;
            font-weight: 500;
        }
    }
}

@media (prefers-color-scheme: dark) {

    .installments {
        background-color: $containerColorDark;

        .title{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
    
            .info{
                display:  flex;
    
                .secion{
                    p{
                        color: $darkTextColor;
                    }
                }
            }
    
            h3{
                color: $darkTextColor;
            }
        }
    }
}