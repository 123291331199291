@import '../../variables.scss';

.home{
    background-color: $main-color;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
        font-size: 18px;
        font-weight: 500;
        color: #FFF;
        margin-top: 20px;
        cursor: pointer;

        &:hover{
            color: #eed7ff;
        }
    }
}