@import '../../variables.scss';

* {
    margin: 0;
    padding: 0;
    font-family: 'madani';
}

.loginpage {
    display: flex;
    height: 100dvh;
    width: 100dvw;
    background: #FBFBFB;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .navbar {
        display: flex;
        width: 100dvw;
        background: #ffffff;
        height: 60px;
        flex-direction: row;
        justify-content: right;
        align-items: center;

        h1 {
            padding-right: 20px;
            font-size: 20px;
            color: $main-color;
            font-family: "madani";
            font-weight: 600;
        }
    }

    .Container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 180px;
            margin-bottom: 20px;
        }

        .loginBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* height: 25dvw; */
            padding: 20px 40px;
            background-color: #FFFFFF;
            border-radius: 26px;
            -webkit-box-shadow: -2px -1px 15px 7px #af52f106;
            -moz-box-shadow: -3px -2px 30px 14px #af52f106;
            box-shadow: -4px -3px 45px 21px #af52f106;
            align-items: center;

            h3 {
                color: $main-color;
                font-size: 20px;
                font-family: "madani";
                font-weight: 500;
                padding-bottom: 20px;
            }

            form {
                display: flex;
                flex-direction: column;

                input {
                    width: 450px;
                    height: 50px;
                    margin-bottom: 10px;
                    text-align: center;
                    border: none;
                    background-color: #AF52F110;
                    border-radius: 8px;
                    outline-color: #AF52F150;
                    font-size: 18px;
                    color: $main-color;
                    font-weight: 500;

                    &::placeholder {
                        color: #AF52F180;
                    }

                }

                button {
                    width: 450px;
                    height: 50px;
                    background-color: $main-color;
                    border-radius: 8px;
                    border: 0;
                    color: #FFFFFF;
                    font-family: "madani";
                    font-weight: 500;
                    font-size: 18px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(175, 82, 241, 0.706)
                    }

                    .loadingIcon{
                        font-size: 18;
                        animation: spin 1s linear infinite;

                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }

                        
                    }
                }


            }
            
            .error{
                color: #ff0000;
                margin-top: 15px;
                font-size: 16px;
            }
        }


    }
}

@media (max-width: 768px) {

    .loginpage {
    
        .navbar {
            h1 {
                padding-right: 15px;
                font-size: 18px;
                font-weight: 500;
            }
        }
    
        .Container {
    
            .logo {
                width: 150px;
                margin-bottom: 15px;
            }
    
            .loginBox {
                padding: 20px;
                margin: 10px;
    
                h3 {
                    font-size: 16px;
                    font-weight: 500;
                }
    
                form {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    input {
                        width: 90vw;
                    }
    
                    button {
                        width: 90vw;
                    }
    
    
                }
            }
    
    
        }
    }
    
}


@media (prefers-color-scheme: dark) {
    .loginpage {
        background: #1e1e1e;

        .navbar {
            background: #272727;
        }

        .Container {
            .loginBox {
                background-color: #272727;

                form {
                    input {
                        background-color: rgba(151, 89, 196, 0.179);
                        outline-color: #2c0f4150;
                        color: #b663f2;

                        &::placeholder {
                            color: #af99bf80;
                        }

                    }
                }


            }
        }
    }
}