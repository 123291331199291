@import '../../variables.scss';

.dashboard {

    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: $background_color;


    .dashboardContainer {
        flex: 5;

            .dataVisualize {
                display: flex;
                gap: 10px;
                margin: 15px;

            }
        
    }

}

@media (prefers-color-scheme: dark) {

    .dashboard {
        background-color: $background_color_dark;

        .dashboardContainer {

            .mainContiner {
                .dataVisualize {

                    .students {
                        background-color: $containerColorDark;
                    }

                    .installments {
                        background-color: $containerColorDark;
                    }
                }
            }
        }
    }
}