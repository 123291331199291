@import '../../../variables.scss';


.schoolAnalytics {
    flex: 1;
    margin: 15px;
    background-color: $container-color;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    padding: 10px 20px;

    h1 {
        font-size: 22px;
        font-weight: 500;
    }

    .sections {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .section {
            flex: 1;
            display: flex;
            justify-content: space-between;
            margin: 15px;
            padding: 15px;
            align-items: center;
            border-radius: 12px;

            .info {
                text-align: right;

                p {
                    color: #767676;
                    font-size: 1.2vw;
                    font-weight: 400;
                }

                span {
                    color: #000000;
                    font-size: 1.6vw;
                    font-weight: 500;
                }
            }

            .icon {
                font-size: 2.8vw;
            }
        }
    }
}

// @media (max-width: 1200px) {
//     .schoolAnalytics {

//         .sections {

//             .section {

//                 .info {

//                     p {
//                         font-size: 1vw;
//                     }

//                     span {
//                         font-size: 2vw;
//                     }
//                 }

//                 .icon {
//                     font-size: 2vw;
//                 }
//             }
//         }
//     }
// }

@media (prefers-color-scheme: dark) {
    .schoolAnalytics {
        background-color: $containerColorDark;

        h1 {
            color: #c8c8c8;
        }
    }
}