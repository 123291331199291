$main-color: #AF52F1;
$main-colorHover: #c685f5;
$text-field-color: #AF52F110;
$text-field-colorDark: #af52f12e;
$container-color: #FFFFFF;
$containerColorDark: #292929;
$background_color: #FBFBFB;
$background_color_dark: #1e1e1e;

$darkTextColor: #c7c7c7;

$borderColor: #F3EEF6;
$sidebarTextColor: #79767B;
$sidebarSectionBgColor: #AF52F130;

$hintColor: #A0A4AB;

$secondary-color: #2ecc71;
$alert-color: #e74c3c;