@import '../../../variables.scss';

.students {
    flex: 1.3;
    padding: 15px;
    background-color: $container-color;
    border-radius: 12px;
    height: 300px;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: center;

    .chart {
        width: 100%;
        height: 90%;
    }

    h3 {
        font-size: 1.3vw;
        font-weight: 500;
    }
}

@media (prefers-color-scheme: dark){
    .students {
        background-color: $containerColorDark;

        h3 {
            color: $darkTextColor;
        }
    }
}