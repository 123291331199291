@import '../../../variables.scss';

.addStudent {

    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: $background_color;


    .addStudentContainer {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: end;

        .title {
            margin: 10px;
            font-size: 18px;
            font-weight: 400;
            color: $sidebarTextColor;
        }

        .formContainer {
            display: flex;
            width: 100%;
            gap: 15px;


            form {
                display: flex;
                width: 100%;
                padding: 10px;

                .section {
                    background-color: $container-color;
                    width: 90%;
                    border: solid #00000010 1px;
                    border-radius: 12px;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 5px;

                    .avatar {
                        width: 70px;
                        height: 70px;
                        background-color: #AF52F112;
                        border-radius: 100%;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        color: $main-color;
                        cursor: pointer;
                        overflow: hidden;


                        &:hover {
                            background-color: #af52f12d;
                        }

                        .icon {
                            font-size: 38px;
                        }

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputSection {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                        justify-content: end;
                        width: 100%;

                        .title {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: right;
                        }

                        input {
                            width: 95%;
                            // height: 35px;
                            padding: 8px;
                            text-align: right;
                            background-color: #F4F4F4;
                            border: none;
                            border-radius: 8px;
                            padding-inline: 10px;
                            font-size: 16px;
                            outline: none;

                            &::placeholder {
                                color: #A0A4AB;
                            }

                            &:focus {
                                outline: solid #AF52F150 1px;
                            }
                        }

                        select {
                            border: none;
                            background-color: #F4F4F4;
                            width: 100%;
                            padding: 10px;
                            font-size: 16px;
                            border-radius: 8px;
                            text-align: right;

                            option {
                                text-align: right
                            }

                        }

                        .react-datepicker-wrapper {
                            width: 100%;

                            .react-datepicker__input-container {
                                width: 100%;

                            }


                        }


                    }

                    .btns {
                        width: 100%;
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;

                        button {
                            width: 100%;
                            border: none;
                            padding: 10px;
                            font-size: 16px;
                            cursor: pointer;
                            border-radius: 8px;
                        }

                        .submit {
                            background-color: $main-color;
                            color: #FFF;

                            &:hover {
                                background-color: $main-colorHover;
                            }
                        }

                        .clear {
                            background-color: #e74c3c;
                            color: #FFF;

                            &:hover {
                                background-color: #ee786b;
                            }
                        }
                    }
                }
            }


        }
    }

}

@media (prefers-color-scheme: dark) {

    .addStudent {
        background-color: $background_color_dark;


        .addStudentContainer {

            .title {
                color: $darkTextColor;
            }

            .formContainer {

                form {
                    .section {
                        background-color: $containerColorDark;
                        border: solid #00000010 1px;

                        .avatar {
                            background-color: #AF52F112;


                            &:hover {
                                background-color: #af52f12d;
                            }

                            .icon {
                                font-size: 38px;
                            }
                        }

                        .inputSection {

                            input {
                                background-color: $background_color_dark;
                                color: $darkTextColor;

                                &::placeholder {
                                    color: #A0A4AB;
                                }

                                &:focus {
                                    outline: solid #AF52F150 1px;
                                }
                            }

                            select {
                                background-color: $background_color_dark;
                                color: $darkTextColor;

                            }


                        }
                    }
                }


            }
        }

    }
}