@import '../../../variables.scss';

.allGrade {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: $background_color;

    .allGradeContainer {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: end;

        .top{
            display: flex;
            width: 98%;
            justify-content: space-between;
            padding: 5px;

            .addGradeSection{
                display: flex;
                align-items: center;
            }

            .title {
                margin: 10px;
                font-size: 18px;
                font-weight: 400;
                color: $sidebarTextColor;
            }

        }

        

        .addGrade {
            background-color: $container-color;
            width: 95%;
            border: solid #00000010 1px;
            border-radius: 12px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 5px;
            overflow-y: scroll;

            .field{
                cursor: pointer;
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .allGrade {
        background-color: $background_color_dark;
    
        .allGradeContainer {
    
            .title {
                color: $darkTextColor;
            }
    
            .addGrade {
                border: solid #00000010 1px;
                background-color: $containerColorDark;
                scrollbar-color: #00000010;

                p{
                    color: white;
                }
                div{
                    color: white;
                }


                button{
                    color: white;
                }

                svg{
                    color: white;
                }

                .field{
                    color: white;
                    .icon{
                        color: white;
                    }
                    
                }
            }
        }
    }
}