.addStdView {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
}

.addStdView_nav {
    /* width: 100%; */
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 10000;
    top: 0;
    -webkit-box-shadow: -1px -1px 20px 5px rgba(0, 0, 0, 0.134);
    -moz-box-shadow: -1px -1px 20px 5px rgba(0, 0, 0, 0.134);
    box-shadow: -1px -1px 20px 5px rgba(0, 0, 0, 0.134);
}

.addStdView_nav p {
    color: #000;
    font-size: 18px;
    margin-right: 15px;
}

.addStdView_nav img {
    width: 80px;
    margin-left: 10px;
}

.addStdView_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formContainer {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60%;
    /* height: 1200px; */
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.addStdView_stdInfo {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
}

.addStdView_stdInfo h4 {
    font-size: 18px;
    font-weight: 500;
}

.addStdView_stdInfo p {
    margin-top: 20px;
    font-size: 16px;
}

.stdInfo_name {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.stdInfo_name input {
    flex: 1;
    font-size: 16px;
    text-align: end;
    padding: 8px;
}

.stdInfo_name select {
    padding: 12px;
    font-size: 16px;
    flex: 1;
    text-align: end;
}

.dateStd {
    padding: 0;
    font-size: 22px;
}

.uploudPhoto {
    height: 120px;
    width: 120px;
    border: 1px solid #4f4f4f;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
}

.stdPhoto {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.teachersForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    text-align: right;
    margin-bottom: 10px;
}

.teachersForm form {
    display: flex;
    flex-direction: column;
}

.teachersForm form p {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #696969;
}

.teachersForm form label {
    display: flex;
    font-size: 16px;
    gap: 8px;
    align-items: center;
    justify-content: end;
    text-align: right;
    flex-direction: row-reverse;
}

.teachersForm form label input {
    width: 15px;
    height: 15px;
}

.btnActions {
    margin-bottom: 90px;
    margin-top: -30px;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.saveBtn {
    padding: 8px 25px;
    font-size: 16px;
    cursor: pointer;
    background: #00c724;
    border: none;
    border-radius: 8px;
    color: #FFF;
}

.saveBtn:hover {
    background: #26df48;
}

.printBtn {
    padding: 8px 25px;
    font-size: 16px;
    cursor: pointer;
    background: #007bc7;
    border: none;
    border-radius: 8px;
    color: #FFF;
}

.printBtn:hover {
    background: #2c92d2;
}

.deleteBtn {
    padding: 8px 25px;
    font-size: 16px;
    cursor: pointer;
    background: #c70032;
    border: none;
    border-radius: 8px;
    color: #FFF;
}

.deleteBtn:hover {
    background: #c63156;
}

@media print {
    .btnActions {
        display: none;
    }
}