@import '../../variables.scss';




.sidebar {
    flex: 1.2;
    background-color: $container-color;
    border-left: solid 1px $borderColor;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .logo {
        width: 130px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .sections {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .secstionActive {
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: end;
            background-color: $sidebarSectionBgColor;
            color: $main-color;
            border-radius: 12px;

            h3 {
                font-weight: 400;
                margin-right: 10px;
                text-align: right;
            }
        }

        .section {
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: end;
            color: $sidebarTextColor;
            border-radius: 12px;

            h3 {
                font-weight: 400;
                margin-right: 10px;
                text-align: right;
            }

            &:hover {
                background-color: $sidebarSectionBgColor;
                color: $main-color;
            }

        }
    }
}

@media (prefers-color-scheme: dark) {
    .sidebar {
        background-color: $containerColorDark;
        border-left: solid 1px $containerColorDark;

        .sections {
            .section {
                color: $darkTextColor;

            }
        }
    }
}